import { Component } from '@angular/core';
import { Router } from '@angular/router';
import{Location} from'@angular/common';
@Component({
  selector: 'menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent {

  constructor(private router: Router,private location: Location) {
  }

  isInHome(): boolean {
    return this.router.url == '/home';
  }
  isInDev(): boolean {
    return this.router.url == '/dev/managed-projects' || this.router.url == '/dev/extended-team' || this.router.url == '/dev/application-maintenance';
  }
  isInExpertise(): boolean {
    return this.router.url == '/expertise';
  }
  isInJobs(): boolean {
    return this.router.url == '/jobs';
  }
  isInAboutUs(): boolean {
    return this.router.url == '/about-us/company' || this.router.url == '/about-us/leadership';
  }
  isAtContactUs() {
    return this.location.path() == '/contact-us';
  }
}
