import { Component, AfterContentInit, ElementRef, Input, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

declare var jQuery: any;

@Component({
  selector: 'section-technologies',
  templateUrl: './technologies.component.html',
  styleUrls: ['./technologies.component.css']
})
export class TechnologiesComponent implements OnInit, AfterContentInit {
  @Input() addClass: string;

  isBrowser;
  constructor(@Inject(PLATFORM_ID) private platformId, private el: ElementRef) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  

  ngOnInit() {
    if(this.isBrowser){
      const selector = jQuery(this.el.nativeElement).find('.tech-carousel');
      jQuery.HSCore.components.HSCarousel.init(selector);
    }
  }

  ngAfterContentInit() {
    // console.log(this.el.nativeElement.outerHTML);
    // jQuery.HSCore.components.HSCarousel.init('.tech-carousel');

  }
}
