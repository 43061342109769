import { Component } from "@angular/core";



@Component({
    selector: "privacypolicy",
    templateUrl: "./privacypolicy.component.html",
    styleUrls: ["./privacypolicy.component.css"]
})
export class PrivacyPolicyCompoent {

}
