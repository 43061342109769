import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Location, isPlatformBrowser } from '@angular/common';

import {Router, NavigationEnd} from "@angular/router";
import { GoogleAnalyticsEventsService } from './google-analytics-events.service';

declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId, private location: Location, public router: Router, public googleAnalyticsEventsService: GoogleAnalyticsEventsService) {
    
    this.isBrowser = isPlatformBrowser(platformId);
    
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && this.isBrowser) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });
  }

  isAtHome() {
    return this.location.path() == '/home';
  }
}
