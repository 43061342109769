import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";


@Component({
    selector: "jobs",
    templateUrl: "./jobs.component.html"
})
export class JobsComponent implements OnInit {

    constructor(
        private title: Title,
        private meta: Meta
    ) { }

    ngOnInit() {

        //Set title and metas
        this.title.setTitle('Jobs | Berries And Co');
        this.meta.updateTag({ name: 'description', content: 'We are always looking for talented developers to join us. Do not hesitate to send your resume, if you would like to be part of our team.' });
        this.meta.updateTag({ name: 'keywords', content: 'berries and co jobs, carrer opportunities, job opportunity' });

    }

}
