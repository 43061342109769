import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'about-us',
    templateUrl: 'aboutus.component.html'
})

export class AboutUsComponent{

    constructor(public router: Router){

    }
    
}