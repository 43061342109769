import { Component, OnInit, Inject } from '@angular/core';
import { Title, Meta, DOCUMENT } from '@angular/platform-browser';


@Component({
    selector: 'dev-content-maintenance',
    templateUrl: './maintenance.component.html'
})
export class DevContentMaintenanceComponent implements OnInit {
    constructor(
        @Inject(DOCUMENT) private doc,
        private title: Title,
        private meta: Meta
    ) {

        //Set title and metas
        this.title.setTitle('Application Maintenance | Berries And Co');
        this.meta.updateTag({ name: 'description', content: 'Your best developers are overwhelmed with maintaining the existing software? There are not enough resources left for your next release? Let us relieve your staff from the maintenance pressure, so they can focus on your important goals.' });
        this.meta.updateTag({ name: 'keywords', content: 'gain focus, improve service level, reduce costs, fix issues, add features, adapt to changes, prevent problems' });

        let link: HTMLLinkElement = this.doc.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', "https://berriesand.co/dev/application-maintenance");
        this.doc.head.appendChild(link);
    }

    ngOnInit() {


    }
}

