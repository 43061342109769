import { Component, OnInit, Inject } from '@angular/core';
import { Meta, Title, DOCUMENT } from '@angular/platform-browser';


@Component({
    selector: 'dev-content-extended-team',
    templateUrl: './extended-team.component.html'
})
export class DevContentExtendedTeamComponent implements OnInit {
    constructor(
        @Inject(DOCUMENT) private doc,
        private title: Title,
        private meta: Meta
    ) {
        this.title.setTitle('Extended team | Berries And Co');
        this.meta.updateTag({ name: 'description', content: "Boost your team's productivity or add extra skills to your team! Let us expand your development capacity with experienced engineers and speed up your project." });
        this.meta.updateTag({ name: 'keywords', content: 'extended team, team expansion, front end developer, frontend developer, back end developer, backend developer, embed developer, software tester, business analyst, engineer' });
        
        let link: HTMLLinkElement = this.doc.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', "https://berriesand.co/dev/extended-team");
        this.doc.head.appendChild(link);
    }

    ngOnInit() {
    }
}

