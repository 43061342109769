import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

declare var initializeCarousel: any;

@Component({
  selector: 'home-content',
  templateUrl: './homecontent.component.html'
})
export class HomeContentComponent implements OnInit {

  static isInitialized = false;

  isBrowser: boolean;
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private title: Title,
    private meta: Meta
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {

    //Set title and metas
    this.title.setTitle('Home | Berries And Co');
    this.meta.updateTag({ name: 'description', content: 'Realize your ideas. We take care of your software projects from prototyping to complex enterprise solutions.' });
    this.meta.updateTag({ name: 'keywords', content: 'software development, programming, coding, web development, szoftver fejlesztés, software fejlesztés, programozás, egyedi szoftver fejlesztés, dotnet, angular, angular2, javascript, css, web, c#, c++, cpp, scrum, agile, prototípus, prototype, mvp, tesztelés, testing' });

    // initialization of scroll animation

    if (!HomeContentComponent.isInitialized) {
      HomeContentComponent.isInitialized = true;
      //        window["HSCore"].init('[data-animation]');
    }


    if(this.isBrowser){
      initializeCarousel('.js-carousel');
    }
  }

}
