import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'aboutus',
  templateUrl: './aboutuscontent.component.html'
})
export class AboutUsContentComponent {
  constructor(
    private title: Title,
    private meta: Meta
  ) { }

  ngOnInit() {

    //Set title and metas
    this.title.setTitle('About our leadership | Berries And Co');
    this.meta.updateTag({ name: 'description', content: 'Berries and Company is a technology company providing consulting services. Focused on helping our clients realizing their creative ideas.' });
    this.meta.updateTag({ name: 'keywords', content: 'Berries And Company, leadership, Adam Sillye, Szilard Peteri' });

  }
}
