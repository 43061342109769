import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeContentComponent } from './homecontent/homecontent.component';
import { IotContentComponent } from './iotcontent/iotcontent.component';
import { DevContentComponent } from './devcontent/devcontent.component';
import { BlockchainContentComponent } from './blockchaincontent/blockchaincontent.component';
import { AboutUsContentComponent } from './aboutuscontent/aboutuscontent.component';
import { CloudMigrationContentComponent } from './cloudmigrationcontent/cloudmigrationcontent.component';
import { MobileContentComponent } from './mobilecontent/mobilecontent.component';
import { PrivacyPolicyCompoent } from './privacypolicy/privacypolicy.component';
import { AboutUsCompanyContentComponent } from './aboutuscontent/aboutuscompany.component';
import { JobsComponent } from './jobs/jobs.component';
import { ExpertiseContentComponent } from './expertise/expertise.component';
import { DevContentProjectComponent } from './devcontent/project.component';
import { DevContentExtendedTeamComponent } from './devcontent/extended-team.component';
import { DevContentMaintenanceComponent } from './devcontent/maintenance.component';
import { AboutUsComponent } from './aboutuscontent/aboutus.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import{ThankYouComponent} from './thank-you/thank-you.component'
const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: HomeContentComponent },
    // { path: 'dev', redirectTo: 'dev/managed-projects', pathMatch: 'full' },
    { path: 'dev', component: DevContentComponent, children:[
        // { path: '', component: DevContentComponent, pathMatch: 'full'},
        { path: "", redirectTo: "managed-projects", pathMatch: "full" },
        { path: 'managed-projects', component: DevContentProjectComponent },
        { path: 'extended-team', component: DevContentExtendedTeamComponent },
        { path: 'application-maintenance', component: DevContentMaintenanceComponent },
    ] },
    { path: 'jobs', component: JobsComponent },
    { path: 'about-us', component: AboutUsComponent, children:[
        { path: '', redirectTo: "company", pathMatch: "full" },
        { path: 'company', component: AboutUsCompanyContentComponent },
        { path: 'leadership', component: AboutUsContentComponent },
    ]},
    { path: 'expertise', component: ExpertiseContentComponent },
    { path: 'blockchain', component: BlockchainContentComponent },
    { path: 'iot', component: IotContentComponent },
    { path: 'cloudmigration', component: CloudMigrationContentComponent },
    { path: 'mobile', component: MobileContentComponent },
    { path: 'privacypolicy', component: PrivacyPolicyCompoent },
    { path: 'contact-us', component: ContactUsComponent },
    {path:'thank-you',component:ThankYouComponent},

    { path: '**', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }


