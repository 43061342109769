import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'dev-content',
    templateUrl: './devcontent.component.html'
})
export class DevContentComponent {
    currentTab = 'project';

    constructor(public router: Router){

    }
}

