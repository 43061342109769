import { Component, OnInit, Inject } from '@angular/core';
import { Title, Meta, DOCUMENT } from '@angular/platform-browser';


@Component({
    selector: 'dev-content-project',
    templateUrl: './project.component.html'
})
export class DevContentProjectComponent implements OnInit{
    constructor(
        @Inject(DOCUMENT) private doc,
        private title: Title,
        private meta: Meta
    ) {

        //Set title and metas
        this.title.setTitle('Managed Projects | Berries And Co');
        this.meta.updateTag({ name: 'description', content: 'Need an available qualified project team? We take care of your software projects from prototyping to complex enterprise solutions.' });
        this.meta.updateTag({ name: 'keywords', content: 'project team, small project, medium project, large project, project management, quality control' });

        let link: HTMLLinkElement = this.doc.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', "https://berriesand.co/dev/managed-projects");
        this.doc.head.appendChild(link);
    }

    ngOnInit() {

    }
}

