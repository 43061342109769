import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import axios from "axios";
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
    selector: "contact-us",
    templateUrl: "./contact-us.component.html",
    styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

    emailForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        subject: new FormControl('', [Validators.required, Validators.maxLength(20)]),
        message: new FormControl('', [Validators.required, Validators.maxLength(400)])
    })
    constructor(
        private title: Title,
        private meta: Meta,
        public router: Router
    ) { }

    ngOnInit() {

        //Set title and metas
        this.title.setTitle('contact-us | Berries And Co');
        this.meta.updateTag({ name: 'description', content: '' });
        this.meta.updateTag({ name: 'keywords', content: '' });

    }

    async Send() {
        await axios.post("https://berriesandco-websites-func.azurewebsites.net/api/BerriesWebFunction?code=iMwWEzP66wO6wtKtvXzLRFarqFy1J8s2LN0q0HGeBvI2vUxDm0g6DA==", {
            email: this.emailForm.value.email,
            subject: this.emailForm.value.subject,
            content: this.emailForm.value.message
        }).then((res) => {
            console.log("done!:", res.status)
            this.router.navigate(["thank-you"]);
        });
    }

}
