import { Component, Inject } from '@angular/core';
import { Meta, Title, DOCUMENT } from '@angular/platform-browser';

@Component({
  selector: 'aboutus-company',
  templateUrl: './aboutuscompany.component.html'
})
export class AboutUsCompanyContentComponent {

  constructor(
    @Inject(DOCUMENT) private doc,
    private title: Title,
    private meta: Meta
  ) {

    this.title.setTitle('About our company | Berries And Co');
    this.meta.updateTag({ name: 'description', content: "Berries and Company is a technology company providing consulting services. Focused on helping our clients realizing their creative ideas." });
    this.meta.updateTag({ name: 'keywords', content: 'Berries and Company, development services' });

    this.meta.addTags([
      {name: 'robots', content: 'index, follow'}
    ]);

    let link: HTMLLinkElement = doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', "https://berriesand.co/about-us/company");
    doc.head.appendChild(link);
  }

  ngOnInit() {
  }
}
