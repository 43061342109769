import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { MenuComponent } from './menu/menu.component';
import { FooterSectionComponent } from './footersection/footersection.component';
import { CheckmarkComponent } from './design/checkmark/checkmark.component';
import { TechnologiesComponent } from './sections/technologies/technologies.component';
import { DevContentProjectComponent } from './devcontent/project.component';
import { DevContentExtendedTeamComponent } from './devcontent/extended-team.component';
import { DevContentMaintenanceComponent } from './devcontent/maintenance.component';
import{ReactiveFormsModule}from'@angular/forms';

// For app routing module
import { HomeContentComponent } from './homecontent/homecontent.component';
import { DevContentComponent } from './devcontent/devcontent.component';
import { ExpertiseContentComponent } from './expertise/expertise.component';
import { BlockchainContentComponent } from './blockchaincontent/blockchaincontent.component';
import { IotContentComponent } from './iotcontent/iotcontent.component';
import { CloudMigrationContentComponent } from './cloudmigrationcontent/cloudmigrationcontent.component';
import { MobileContentComponent } from './mobilecontent/mobilecontent.component';
import { AboutUsContentComponent } from './aboutuscontent/aboutuscontent.component';
import { AboutUsCompanyContentComponent } from './aboutuscontent/aboutuscompany.component';
import { JobsComponent } from './jobs/jobs.component';
import { PrivacyPolicyCompoent } from './privacypolicy/privacypolicy.component';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AboutUsComponent } from './aboutuscontent/aboutus.component';
import {ContactUsComponent}from'./contact-us/contact-us.component'
import {GoogleAnalyticsEventsService} from "./google-analytics-events.service";
import { FormsModule } from '@angular/forms';
import{ThankYouComponent} from './thank-you/thank-you.component';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    FooterSectionComponent,
    ThankYouComponent,
    CheckmarkComponent,
    TechnologiesComponent,
    DevContentProjectComponent,
    DevContentExtendedTeamComponent,
    DevContentMaintenanceComponent,
    ExpertiseContentComponent,

    HomeContentComponent,
    DevContentComponent,
    ContactUsComponent,
    ExpertiseContentComponent,
    BlockchainContentComponent,
    IotContentComponent,
    CloudMigrationContentComponent,
    AboutUsComponent,
    MobileContentComponent,
    AboutUsContentComponent,
    AboutUsCompanyContentComponent,
    JobsComponent,
    PrivacyPolicyCompoent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CommonModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [
    GoogleAnalyticsEventsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
